.content.admin {
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100vh;  
    background: #4b5568;
}

.content.admin .pannel {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.content.admin .login {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid #48505f;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    min-width: 300px;
}

.content.admin form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border: 1px solid #48505f;
    border-radius: 5px;
}

.content.admin form input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #48505f;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
}

.content.admin input::placeholder{
    color: rgb(197, 194, 194);
}

.content.admin input[type="submit"] {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #48505f;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
}

.admin-logged {
    display: flex;
    gap: 10px;
    padding: 15px;
    border: 1px solid #48505f;
    border-radius: 5px;
    background-color: rgba(84, 83, 83, 0.486);
    width: 100%;
}

.admin-logged .card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border: 1px solid #48505f;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    flex: 1;
}

.admin-logged .card .radioInfo .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #48505f;
}

.admin-logged .card .radioInfo p {
    margin: 0;
    padding: 0;
    text-transform: capitalize;
}

.editPermissions form {
    flex-direction: row!important;
    background-color: rgba(255, 255, 255, 0.2);
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.editPermissions form input[name="permissions"] {
    font-weight: 600;
    color: white;
    font-size: 1.2rem;
}

.uPermissions .permissions {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;

}

.uPermissions .permissions li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #48505f;
}

.uPermissions .permissions li button {
    color: white;
    background-color: transparent;
    border: 1px solid #48505f;
    padding: 10px;
}
