.App {
  text-align: center;
}

.content {
  /*
* Created with https://www.css-gradient.com
* Gradient link: https://www.css-gradient.com/?c1=3f411c&c2=298896&gt=l&gd=dbr
*/
  background: #3f411c;
  background: linear-gradient(315deg, #3f411c, #298896);
  min-height: 100vh;
  display: flex;
  font-size: calc(10px + 1vmin);
  color: white;
}

.content .burger {
  display: none;
}

.content .burger .line {
  width: 30px;
  height: 2px;
  background-color: white;
  margin: 5px;
}

.content .playlists {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.098);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.3px);
  -webkit-backdrop-filter: blur(7.3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.playlists.active {
  display: flex !important;
  position: fixed;
  padding: 0;
  width: 100%;
  z-index: 10;
}

@media (max-width: 1000px) {
  .content .playlists {
    display: none;
  }

  .playlists .inner {
    padding: 10px;
  }

  .inner .item-container {
    padding: 10px;
  }

  .content .burger {
    display: block;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 100;
  }
}

.content .player {
  display: flex;
  flex: 4;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.player .inner {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  width: 500px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  gap: 20px;
  position: relative;
}

.rhap_button-clear {
  color: white !important;
}

@media (max-width: 700px) {
  .player .inner {
    width: 70%;
  }
}

.player .inner .audio {
  width: 110%;
  color: black;
  position: absolute;
  bottom: 20px;
}

.inner .alert {
  background-color: #45313d;
  color: white;
  border: 1px solid #48505f;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 24px;
}

.playlists .inner {
  width: 100%;
}

.audio .rhap_container {
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #48505f;
  background-color: #0a0a0a24;
  position: relative;
}

.audio .rhap_main-controls {
  position: absolute;
  left: 0;
  right: 0;
}

.audio .rhap_time {
  color: white;
}

.player .radio-info {
  text-transform: capitalize;
  width: 80%;
  margin-bottom: 70px;
}

.radio-info h3 {
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  padding-bottom: 10px;
}

.radio-info img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 10px;
}

.rhap_additional-controls {
  display: none !important;
}

.rhap_progress-section {
  flex: 1 !important;
}

.playlists .inner .item-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.radio-item p {
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}
.radio-item button {
  color: white;
  background-color: transparent;
  border: 1px solid #48505f;
  padding: 10px;
}

.radio-item button:hover {
  background-color: #48505f;
  cursor: pointer;
}

.item-container .radio-item.selected {
  box-shadow: 0px 0px 10px 0px #48505f;
  border-radius: 5px;
}

.item-container.premium {
  margin-top: 20px;
}

.item-container.premium form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border: 1px solid #48505f;
  border-radius: 5px;
}

.item-container.premium form input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #48505f;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.item-container.premium button {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #48505f;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.item-container.premium form input::placeholder {
  color: whitesmoke;
}

.item-container.premium .alert {
  background-color: #503445;
}

.item-container.premium h4 {
  margin: 0;
  padding: 0;
}
